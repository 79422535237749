@use "./config/" as *;
@forward "./main-scss/";
.waf-about {
    @extend %px-0;
    @extend %pb-0;
    @extend %pt-12;
    @extend %hidden;
    .waf-head {
        @extend %px-2;
        .title {
            @extend %relative;
            @extend %font-32-pb;
            @extend %primary-700;
            &::before {
                content: attr(data-title);
                position: absolute;
                bottom: 30%;
                left: 0;
                font-size: 200%;
                line-height: 1;
                opacity: 0.5;
                @extend %uppercase;
                @extend %neutral-800-1;
            }
        }
        .description p {
            line-height: 1.7;
            @extend %font-14-pm;
            @extend %mb-4;
            @extend %mt-3;
        }
    }
    .waf-body {
        img {
            aspect-ratio: 21/9;
        }
    }
    &-us {
        --_title-color: var(--neutral-50);
        @extend %neutral-50;
        @include background(clr(primary-900), "pattern/mobile/about-bg.png", center top/cover);
        .waf-head .title {
            @extend %neutral-50;
            &::before {
                color: var(--neutral-50);
                opacity: 0.05;
            }
        }
    }
    &-mission {
        @include background(null, "pattern/white-bg.png", top / cover no-repeat);
    }
}
@include mq(col-lg) {
    .waf-about {
        display: flex;
        align-items: center;
        padding-block: 9rem;
        position: relative;
        &::before {
            width: 21rem;
            height: 22rem;
            content: "";
            position: absolute;
            bottom: 20%;
            left: 0;
            opacity: 0.3;
            transform: translateX(-20%);
            @include background(null, "pattern/dc-pattern.png", center/cover);
        }
        &::after {
            content: "";
            width: 25vw;
            left: 0rem;
            height: 2rem;
            position: absolute;
            bottom: 0;
            opacity: 0.1;
            @include background(null, "svg/footer-pattern-2.svg", left/2rem 2rem space)
        }
        &-us {
            @include background(clr(primary-900), "pattern/about-bg.png", center/cover);
            &::after {
                opacity: 0.6;
            }
            &::before {
                opacity: 1;
                mix-blend-mode: plus-lighter;
            }
        }
        .waf-head {
            padding-left: var(--container-white-space);
        }
        .waf-body {
            width: 65%;
            flex-shrink: 0;
        }
        &-vision {
            flex-direction: row-reverse;
            &::after {
                right: 0;
                left: auto;
            }
            &::before {
                right: 0;
                left: unset;
                transform: translateX(20%);
            }
            .waf-head {
                padding-left: var(--space-3);
                padding-right: var(--container-white-space);
            }
        }
    }
}